.letterContainer {
  position: relative;
  height: fit-content;
  background: rgb(255, 253, 253);
}

.letter {
  padding: 80px 0px;
  padding-bottom: 0px;
}
.letter a:not(.disable-highlight) {
  color: black;
  background: #f221f363;
  padding: 2px 10px;
  border-radius: 3px;
}

.letter .textblock {
  margin: 1em auto;
  max-width: 600px;
  padding: 0px 20px;
}

.imageHolder {
  background: radial-gradient(#3c3c3c, #212121);
  padding: 20px;
  margin: 3em 0px;
  text-align: center;
}

.videoHolder {
  background: radial-gradient(#3c3c3c, #212121);
  padding: 60px 20px;
  text-align: center;
}
.video {
  border: 0;
  border-radius: 10;
}
.imageHolder img {
  width: 33%;
  min-width: min(310px, 100vw);
  border-radius: 10px;
  margin: 10px;
}

.excerpt {
  font-style: italic;
}
